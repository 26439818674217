import { ApolloClient, InMemoryCache,  } from "@apollo/client";


export const apolloClient = new ApolloClient({
    uri: `${process.env.NEXT_PUBLIC_WORDPRESS_API_URL}/graphql`,
    credentials: 'include',
    cache: new InMemoryCache(),
});

export function getSSRClient(cookies:string)

{
    return new ApolloClient({
    cache: new InMemoryCache(),
    credentials: 'include',
    uri: `${process.env.NEXT_PUBLIC_WORDPRESS_API_URL}/graphql`,
    ssrMode: true,
    headers:
    {
        cookie: cookies,

    }
  });
}
