import * as Sentry from '@sentry/nextjs';
let breadCrumbRegEx = new RegExp(/https:\/\/(d319gjk37uo5ab\.cloudfront|collector|bam|live\.rawumberstudios)/);

export function beforeBreadcrumbHelper(breadcrumb : Sentry.Breadcrumb, hint? : Sentry.BreadcrumbHint)
  {

    if (breadcrumb.message?.match(breadCrumbRegEx))
    {
      return null;
    }

    if (breadcrumb.category=='http' || breadcrumb.category=='xhr')
    {
        let x = breadcrumb.data?.url;        

        if (x?.match(breadCrumbRegEx))
        {
         // console.log("Not saving breadcrumb!!");
          return null;
        }  
    }

    return breadcrumb;

  }