import { DateTime } from "luxon";
import { consoleLog } from "../lib/logger";
import * as Sentry from "@sentry/nextjs";
import useAuth from '../lib/useAuth';

import * as jose from 'jose';
import { useEffect } from "react";

export default function TokenRefresher()
{
    const {authTokenRef, refreshUserInformation} = useAuth();

    useEffect(()=>
    {
    //consoleLog("Setting up timer");

    let timer = setInterval(async ()=>
    {
      //consoleLog("Checking token");
      let token =authTokenRef?.current;

      //consoleLog(token);

      if (token)
      {

       
        const payload= await jose.decodeJwt(token);

        //consoleLog(payload);
        let dt = DateTime.fromMillis(1000 * (payload.exp as number));

        if (dt<DateTime.now().toUTC().plus({minute:10}))
        {
          //consoleLog("Refreshing token");
          Sentry.addBreadcrumb({message:"Refreshing token"});
          refreshUserInformation();
        }
        //consoleLog(dt);

      }

    }, 1000 * 60 * 60 * 12);

    return (() => { clearInterval(timer);})
    }, []);

    return <></>
}
