import { DateTime } from "luxon";
import {Mutex} from 'async-mutex';


type StoredObject =
{
    value? : any,
    ttl? : number,
    dateCached: DateTime,
}

class LocalCache {


    _store : Map<string, StoredObject> = new Map<string, StoredObject>;
    _mutexes: Map<string,Mutex> = new Map<string, Mutex>;

    constructor()
    {
        let g = global as any;

        if (typeof g.rusCache=='undefined')
        {
            g.rusCache = this._store;
            g.rusMutexes = this._mutexes
        }
        else
        {
            this._store = g.rusCache;
            this._mutexes = g.rusMutexes;
        }
    }

    async getObject<T>(key: string, getValue : () => Promise<any>, ttl?:number)
    {

        let mutex = this._mutexes.get(key);
        if (!mutex)
        {
            mutex = new Mutex();
            this._mutexes.set(key, mutex);
        }

        let x = this;

       async function internalGetter()
        {
       
            let debug = false && (key=='currencies');
    
            debug && console.log(key);
    
            let currentValue = x._store.get(key);
    
            let mustRefresh = false;
    
            if (!currentValue || typeof currentValue.value == 'undefined')
            {
                mustRefresh = true;
            }
            else
            {
                if (currentValue.ttl && -currentValue.dateCached.diffNow().as('seconds')> currentValue.ttl)
                {
                    mustRefresh = true;
                }
            }
    
            let value: any = null;
    
            if (mustRefresh)
            {
                let newValue = {...currentValue, loading: true, ttl:ttl, dateCached: DateTime.now()};
    
                
                debug && console.log("Loading " + key);
                newValue.loading = true;
                x._store.set(key, newValue);

                debug && console.log("About to retrieve data for " + key);
                let a = getValue();

                try
                {
                    value = await a;
                    debug && console.log("Loaded" + key);
                    debug && console.log(value);
                }
                catch (error)
                {
                    throw(error);
                }
                finally
                {
                    debug && console.log("finally");
                }
                
                newValue.value = value;

                x._store.set(key, newValue);
            }
            else
            {
                debug && console.log("Fetching " + key + " from cache");
                value = currentValue?.value;
            }
    
            return value as T;
    
        }

         let result = await mutex.runExclusive(internalGetter);
         return result;
        

    }

}

const localCache = new LocalCache();

export {localCache}


