
import {Open_Sans} from "next/font/google";
const open = Open_Sans({ subsets: ['latin'] })

export default function GenericHead()
{
    return <>
<meta name="viewport" content="initial-scale=1, width=device-width" />    
<style jsx global>{
`
        html {
          font-family: ${open.style.fontFamily};
        }
      `}
</style>
</>
}
