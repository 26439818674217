import Cookies from 'universal-cookie';
import pino from 'pino';
import * as Sentry from "@sentry/nextjs";
import { AxiosError } from 'axios';
import {ClientRequest} from 'http'

//import newrelic from 'newrelic'

const logger = pino({
    name: 'rus-web-site',
    level: 'debug'
});

export function logEvent(event : string, data :any) {
    //has to be a beacon because user may be navigating off page

    if (typeof window == 'undefined')
    {
        consoleLog("Trying to log something on the server");
        Sentry.captureMessage("Trying to client log on server", {extra: {event:event, data:data}})          
        return;
    }


    /*if (typeof rus_cookies_allow_tracking !== 'undefined')*/ {
        /*if (rus_cookies_allow_tracking === true)*/ {
            
            let cookies = new Cookies();
            let c = '' + cookies.get('rus_browser_id');
            let t = '' + cookies.get('rus_token');
            let log = (process.env.NEXT_PUBLIC_NO_LOG!='1');

            if (log)
            {
                if (c != "") {
                    let p = { browserId: c, token: t, event: event, metadata: JSON.stringify(data, (_, v) => { if (v != null) return v; }) };
                    try
                    {
                        navigator.sendBeacon(process.env.NEXT_PUBLIC_LOGGER_ROUTE + "/logevent", JSON.stringify(p));
                    }
                    catch (e)
                    {
                        //fail silently
                    }

                }
            }
            else
            {
                consoleLog(`logging ${log?'':'[Disabled] '}${c}/${t}/${event}:\r\n${JSON.stringify(data)}`);
            }
        }
    }
}

export function consoleError(e:any, extraData?:any)
{
    console.error(e);
    logger.error(e);

    if (process.env.NEXT_PUBLIC_NO_SENTRY!="1")
    {
     let x;

     if (extraData)
     {
         x={extra:{extraData: extraData}}
     }
     else
     {

     }

     Sentry.captureException(e, x);
    }

     /*
    var err = new Error();
    //let stack = err.stack;

    
    if (typeof window == "undefined") {
        Sentry.captureException(e);
        //const newrelic = require("newrelic");
        //newrelic.noticeError(e, {stack: stack??''});

      } else {
       //(window as any).newrelic?.noticeError(e, {stack: stack??''});
           //this is async, but let's just fire and forget

           if (process.env.NEXT_PUBLIC_NO_SENTRY!="1")
           {
            let x;

            if (extraData)
            {
                x={extra:{extraData: extraData}}
            }
            else
            {

            }

            Sentry.captureException(e, x);
           }
      }*/
}

export function consoleLog(...args : any)
{
    if (process.env.NEXT_PUBLIC_DEBUG_LOG=='1')
    {
        args.forEach((log : any) =>
            {
        if (typeof log === 'string')
        {
        //
        console.log(Date.now() + ":" + log);
        //logger.info(log);
        }
        else
        {
            console.log(Date.now() + ":" + JSON.stringify(log));
            //logger.info(log);
        }
    });
    }
    else if (process.env.NEXT_PUBLIC_DEBUG_LOG=='PINO')
    {
        //console.log("PINO!");
        args.forEach((log : any) =>
        {
            logger.info(log);
    }
        );
}

}

export function trackFathomGoal(goal : string)
{
    if (typeof window!=='undefined')
    {
        (window as any).fathom?.trackGoal(goal, 0);
    }
}
export function logSubscribeToNewsletter()
{
    logEvent("subscribed_to_newsletter", { page: window.location.href });
    trackFathomGoal('ZDEXRGSZ');
}

export function logRequestedFreebies()
{
    logEvent("requested_freebies", { page: window.location.href });
    trackFathomGoal('XHNFHCIW');
}

export function startSessionIfNecessary()
{

    let cookies = new Cookies();
    let s = cookies.get("rus_session_started");


    if (!s)
    {
        logEvent("browser_initiated", { page: window.location.href });
        logEvent("session_started", { page: window.location.href, referrer: document.referrer });
        logEvent("page_loaded", { page: window.location.href });

        cookies.set("rus_session_started", "yes", {path:"/"});
    }
}
export async function registerBrowser()
{

    let cookies = new Cookies();
    let c = cookies.get('rus_browser_id');
    let t = cookies.get('rus_token');

    //Safari has a 7 day limit, so let's reset the cookie
    //Also, Safari 12 didn't sent cookies with post data if I don't set the samesite attribute, so reset it her

    if (!c || !t)
    {
        const r = await fetch('/n/api/registerbrowser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials:"include"
            
          });     
          
          let j = await r.json();
          c = j.browserId;
          t = j.token;
        }
    
        Sentry.addBreadcrumb({message: `Setting browser id / token (${c},${t})`});
        Sentry.setContext("rus_browser", {id:c, token:t});
        Sentry.setUser({id: c});
    //because I wasn't setting the domain correctly!

    cleanCookie("rus_browser_id");
    cleanCookie("rus_token");

    cookies.set("rus_browser_id",c, {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 800), sameSite: "lax", path:"/"});
    cookies.set("rus_token",t, {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 800),  sameSite: "lax", path:"/"});

    startSessionIfNecessary();

    //consoleLog(`Set cookie/browser to ${c},${t}`);
    return [c,t];
}

function cleanCookie(name : string)
{
    let cookies = new Cookies();

    const paths = [
        '/',
        '/n',
        '/n/videos',
        '/n/live',
        '/n/events',
        '/n/booking'
    ];

    paths.forEach(p=>
        {
            cookies.remove(name, {path:p});
            cookies.remove(name, {path:p, sameSite:"lax"});
        });

}

export async function setBrowserEmail(email?: string)
{
   // consoleLog("Setting browser for " + email);
    if (email && email.length!=0)
    {
        let cookies = new Cookies();
        
        let browserId = '' + cookies.get('rus_browser_id');
        let token = '' + cookies.get('rus_token');
    
        const r = await fetch('/n/api/setbrowseremail', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email, token:token, browserId: browserId}),
            credentials:"include"
            
          });     
          
    }

}

export function handleAxiosError(err: any)
{
    let error = err as AxiosError;

    if (error.isAxiosError)
    {
        let ex = new Error("Axios error");

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            Sentry.addBreadcrumb({message: 'Axios response error'});
            Sentry.captureException(ex, {extra: {type:'response', axios:error.toJSON(), responseHeaders:error.response.headers, requestHeaders:error.request && error.request.headers}});
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            Sentry.addBreadcrumb({message: 'Axios request error'});

            let extraData : any;

            if (typeof window == 'undefined')
            {
                let request = error.request as ClientRequest;
                extraData = {
                    headers: request?.getHeaders()
                }
                
            }
            else
            {
                let request = error.request as XMLHttpRequest;
                extraData =
                {
                    response: request.response,
                    status: request.status,
                    statusText: request.statusText,
                    responseHeaders: request.getAllResponseHeaders(),
                    request: request,
                }                 
            }

            extraData.type = "request";
            extraData.axiosError = error.toJSON();
            extraData.configHeaders= error.config?.headers,
            extraData.config= error.config
            Sentry.captureException(ex, {extra: extraData, fingerprint:['axios_request_error']});
        } else {
            // Something happened in setting up the request that triggered an Error
            Sentry.addBreadcrumb({message: 'Axios other error'});
            Sentry.captureException(ex, {extra: {type:'other', axios:error.toJSON()}});
        }
    }
    else
    {
        consoleError(err);
    }

}
