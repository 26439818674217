import { DateTime } from "luxon";
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import { LocalizationInformation, RusContextProps, ShoppingCartItem, ShoppingCartItemType, Tutor } from "./definitions";
import * as Sentry from "@sentry/nextjs";
import { consoleError, handleAxiosError } from "./logger";
import axios from "axios";
import { sessionStorageGetItem, sessionStorageRemoveItem, sessionStorageSetItem } from "./utils";
import { testXHR } from "./myxhr";

const rawUmberContextDefaultValues: RusContextProps = {
    hidePopUp: false,
    setHidePopUp: () => {},
    localizationInformation:  null,
    setLocalizationInformation: () => {},
    currencies: null,
    timeOffset:null,
    //tutorMap:null,
    tutors:null,
    setShoppingCartItem: () => {},
    shoppingCartItemRef: null
       
};

const RawUmberContext = createContext<RusContextProps>(rawUmberContextDefaultValues);

export function useRawUmberContext() {
    return useContext(RawUmberContext);
}


type Props = {
    children: ReactNode
} & RusContextProps;

export function RawUmberProvider({ children, ...props }: Props) {

    const [hidePopUp, setHidePopUp] = useState(false);
    const [localizationInformation, setLocalizationInformation] = useState(props.localizationInformation);
    const [currencies, setCurrencies] = useState(props.currencies);
    const [timeOffset, setTimeOffset] = useState<number | null>(props.timeOffset);
    //const [tutorMap, setTutorMap] = useState<Map<number, string> | null>(props.tutorMap);
    const [tutors, setTutors] = useState<Tutor [] | null>(props.tutors);
    const shoppingCartItem=useRef<ShoppingCartItem | null>(null);
    const shoppingCartTag = "shopping-cart-item";

    function setShoppingCartItem(x: ShoppingCartItem | null)
    {
        shoppingCartItem.current = x;       

        if (x)
        {            
            sessionStorageSetItem(shoppingCartTag, JSON.stringify(x));
        }
        else
        {
            sessionStorageRemoveItem(shoppingCartTag);
        }

    }
    async function initializeDataFromServer()
    {
    //note that this is CACHED for non-logged in users
    //but only get localization information on the CLIENT for all users anyway

    let l: LocalizationInformation | null = null;
    const localizationCookieName = "rus_localization_information";

    let ck2 : Cookies;

    if (typeof window !== 'undefined')
    {
      ck2 = new Cookies(); 

      let j = ck2.get(localizationCookieName);
      //let options = { method: 'GET',  cache: "no-store" as RequestCache};

      if (!j)
      {
        //("***Fetching from server");
        try
        {
            let url = process.env.NEXT_PUBLIC_ELTON_ROUTE + '/mycountrycode?safari=' + Math.floor(Math.random() * 100000); 

            let at2 = await axios.get(url, {headers: {'Cache-Control': 'no-store'}});
            
            l = at2.data as LocalizationInformation;

            if (l.CurrencyCode!="USD" && l.CurrencyCode!="EUR")
            {
                l.CurrencyCode = "GBP";
            }
        }
        catch(e)
        {
            Sentry.captureMessage("Couldn't get timezone", {extra:{error:e}});
            Sentry.addBreadcrumb({message: "Setting to local timezone"});

           //handleAxiosError(e);
           l = {
            CountryCode:'UK',
            GMTOffset:0,
            CurrencyCode:'GBP',
            TimeZoneCode:'GMT'
           }        
        }

        ck2.set(localizationCookieName, l);
      }
      else
      {

        l = j;
      }

      setLocalizationInformation(l);

      if (typeof window != 'undefined')
      {
        Sentry.setContext("localization", {value:l});
      }

      if (timeOffset == null)
      {

        Sentry.addBreadcrumb({message: "About to get time"});

        try
        {
            //testXHR();
            let at3 = await axios.get(process.env.NEXT_PUBLIC_ELTON_ROUTE + '/now?safari=' + Math.floor(Math.random() * 100000), {headers: {'Cache-Control': 'no-store'}});
            
            let i = at3.data;
            Sentry.addBreadcrumb({message: "Read time"});
            let d = i.ServerUTC;
            let dt: DateTime = DateTime.fromISO(d);
            let now = DateTime.now().toUTC();
            let diff = dt.diff(now);
            setTimeOffset(diff.as('milliseconds'));
            Sentry.setContext("timeoffset", {value:diff.as("milliseconds")});

        }
        catch (e)
        {
            //handleAxiosError(e);
            Sentry.captureMessage("Couldn't get time offset", {extra:{error:e}});
            Sentry.addBreadcrumb({message: "Setting time to 0"});
            setTimeOffset(0);
            Sentry.setContext("timeoffset", {value:0});
        }
  
        //let at3 =  await fetch(process.env.NEXT_PUBLIC_ELTON_ROUTE + '/now', options);
        //Sentry.addBreadcrumb({message: "About to read time"});
        
      }

    }
    }
    useEffect(() =>
    {
        initializeDataFromServer();
        let x = sessionStorageGetItem(shoppingCartTag);


        if (x)
        {
            shoppingCartItem.current = JSON.parse(x);
           
        }
        else
        {
          
            shoppingCartItem.current = {itemType: ShoppingCartItemType.none};
        }
    },[]
    );

    function externalSetLocalizationInformation(i : LocalizationInformation)
    {
        setLocalizationInformation(i);
    }

    const value : RusContextProps = {
        hidePopUp,
        setHidePopUp,
        localizationInformation,
        setLocalizationInformation: externalSetLocalizationInformation,
        currencies,
        timeOffset,
        //tutorMap,
        tutors,
        shoppingCartItemRef: shoppingCartItem,
        setShoppingCartItem 
    };

    return (
        <>
            <RawUmberContext.Provider value={value}>
                {children}
            </RawUmberContext.Provider>
        </>
    );
}


