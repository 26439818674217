import { gql } from "@apollo/client";
import React, { createContext, useContext, ReactNode, useState, useEffect, useRef } from "react";
import { RUSError, RusAuthProps, SubscriberLevel, SubscriptionStatus, UserInformation, WPError } from "./definitions";
import { consoleError, consoleLog, handleAxiosError } from "./logger";
import { DateTime } from "luxon";
//import { getStaticPaths } from "../pages/[uri]";
const jose = require('jose');
import * as Sentry from "@sentry/nextjs";
import axios from "axios";


const DEFAULT_STATE: RusAuthProps = {
    loggedIn: false,
    userInformation: undefined,
    authToken:'',
    authTokenRef: undefined,
    refreshUserInformation: () => {},
    logIn : () => {return Promise.resolve();},
    logOut: () => {return Promise.resolve();}
}
;

const AuthContext = createContext(DEFAULT_STATE);

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($username: String!) {
    sendPasswordResetEmail(
      input: { username: $username }
    ) {
      user {
        id
        email
      }
    }
  }
`;

type AuthProps = RusAuthProps & 
{
  children: ReactNode,
}

export function AuthProvider(props : AuthProps) {
  
    const [authToken, setAuthToken] = useState(props.authToken);
    const [loggedIn, setLoggedIn] = useState(props.loggedIn);
    const [toggleRefresh, setToggleRefresh] = useState(0);
    const toggleRefreshRef = useRef(0);

    const authTokenRef = useRef(props.authToken);
    

    const [userInformation, setUserInformation] = useState<UserInformation | undefined>(props.userInformation);
    //const user = data?.viewer;
    //const loggedIn = Boolean(user);
    //const initialLoggedInState = props.initialLoggedInState;
 
    useEffect(() =>
    {
      
      refreshTokens();

      

    }, [toggleRefresh]

    );

    async function logOut()
    {
      try
      {
      let a = await fetch('/n/api/wp-loout', { method: 'POST'});
      }
      catch (e)
      {
        consoleError(e);
      }      

    }

    async function refreshTokens()
    {
     // consoleLog("Refreshing!!");
      try
      {
      Sentry.addBreadcrumb({message: "About to refresh token"});

      /*
      let at =  await fetch('/n/api/getjwttoken', {
        method: 'GET',  cache:"no-store", credentials:"include"    
      });        
*/

      let at = await axios.get('/n/api/getjwttoken?safari=' + Math.floor(Math.random() * 100000), {withCredentials:true, headers: {'Cache-Control': 'no-store'}});
      let k = at.data;

      //Sentry.addBreadcrumb({message:'Axios response', data:at});

      //let k = await at.json();
/*
      Sentry.addBreadcrumb({message:"JSON headers coming up"});

      Sentry.addBreadcrumb({message:"JSON headers", data: {"x-rus-cache-date": at.headers.get("x-rus-cache-date"), "x-rus-cache-status":at.headers.get("x-rus-cache-status")}});
            */
      let u = await getUserFromAuthToken(k.token);
      setUserInformation(u);
      setLoggedIn(u.user_id!=0);

      if (u.logged_in)
      {
        setAuthToken(k.token);
        authTokenRef.current = k.token;
      }
      else
      {
        setAuthToken(undefined);
        authTokenRef.current = undefined;
      }  
    }
    catch (e)
    {
      Sentry.addBreadcrumb({message: "Errored refreshing token - resetting to blank"});
      //Sentry.captureException("Couldn't refresh token");
      Sentry.captureMessage("Couldn't refresh token", {extra:{error:e}});
      //handleAxiosError(e);
      setAuthToken(undefined);
      setLoggedIn(false);
      authTokenRef.current = undefined;
    }

    }

    async function logIn(userName: string, password: string, remember: boolean)
    {
      try
      {
      let a = await fetch('/n/api/wp-login', {
        method: 'POST',
        credentials:"include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: userName,
          password: password,
          remember: remember
        }),
      });

      let r = await a.json();

      if (!r.loggedIn)
      {
        throw new RUSError("Invalid username or password");
      }

      await refreshTokens();


    }
    catch (e)
    {

      if (!(e instanceof RUSError))
      {
        consoleError(e);
        await refreshTokens();
      }   
      else
      {
        throw e;
      }



    }

    }


    function refresh()
    {    
      //some sort of bug with calling it in a timer.

      //consoleLog("Toggling refresh " + toggleRefreshRef.current);
      let nr = toggleRefreshRef.current+1;
      setToggleRefresh(nr);      
      toggleRefreshRef.current = nr;
    }


    
/*
    const value : AuthData = {
        //loggedIn,
        loading,
        error,
        //initialLoggedInState,
        loggedIn: (((user === undefined || user === null)?undefined:true) || initialLoggedInState),   
        authToken,
        userInformation,
    }

    if (user)
    {
      value.userName = user.id;
      value.emailAddress = user.email;
    }*/

    //console.log(props.loggedIn);

    const value : RusAuthProps =
    {
      loggedIn,
      userInformation,
      authToken,
      authTokenRef,
      refreshUserInformation: refresh,
      logIn: logIn,
      logOut: logOut
    }
  
    

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext);

export default useAuth;

export function subscriptionDescription(user : UserInformation)
{
  return subscriptionDescriptionFromLevel(user.subscriber_level);
}

export function subscriptionDescriptionFromLevel(level : SubscriberLevel | undefined)
{
  if (!level)
  {
    return 'Unknown';
  }
  switch (level)
  { 
    case SubscriberLevel.gold:
      return 'Gold';
      break;
    case SubscriberLevel.standard:
      return 'Standard';
      break;
    case SubscriberLevel.image:
      return 'Image';
      break;
    default:
      return 'Unknown';
  }
}

export async function userExists(user : string)
{
  
  let at =  await fetch('/n/api/userexists', {
    method: 'POST',         
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({user:user}),
  });        
  let k = await at.json();
  return k;
}

export async function createWordpressUser(user : string, email:string, firstName: string, lastName:string, password:string)
{
  
  let at =  await fetch('/n/api/createwordpressuser', {
    method: 'POST',         
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({user:user, email:email, password:password, firstName:firstName, lastName:lastName}),
  });        
  let k = await at.json();

  if (k.status!='OK')
  {
    throw new WPError(k.message);
  }
  return k;
}

export async function updateUserDetails(currentPassword: string, email:string, newPassword:string, userName: string, firstName: string, lastName: string)
{
  let at =  await fetch('/n/api/updateuserdetails', {
    method: 'POST',         
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({currentpassword: currentPassword, newpassword: newPassword, email:email, firstname:firstName, lastname:lastName, username:userName}),
  });        
  let k = await at.json();

  if (k.status!='OK')
  {
    throw new WPError(k.message);
  }
  return k;
}

export async function sendPasswordResetEmail(username: string)
{
  let at =  await fetch('/n/api/sendpasswordresetemail', {
    method: 'POST',         
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({username: username}),
    credentials:"include"
  });        
  let k = await at.json();

  if (k.status!='OK')
  {
    throw new WPError(k.message);
  }
  return k;
}

export function subscriptionRenewalDate(user : UserInformation | undefined)
{
  
  if (!user)
  {
    return DateTime.now();
  }
  if (user.expiry_date_utc_ticks)
  {
    return DateTime.fromMillis(user.expiry_date_utc_ticks);
  }

  return DateTime.now();
}

export async function getUserFromAuthToken(token : string)
{
  let pub = process.env.NEXT_PUBLIC_PUBLIC_KEY as string;


  const publicKey = await jose.importSPKI(pub, 'RS512');

  try
  {

    //if (typeof window != 'undefined')
    //{
      Sentry.setContext("token", {value: token});
      Sentry.addBreadcrumb({message:"About to verify token " + token});
    //}
    //else
    //{
    
    //}

  const {payload, protectedHeader}= await jose.jwtVerify(token, publicKey, {issuer: "https://rawumberstudios.com"});

  Sentry.addBreadcrumb({message:"Verified token"});
  return payload as UserInformation;
  }
  catch (e)
  {
    consoleLog("Expired JWT token. Returning not-logged on user.");
    consoleError(e,"Token: " + token);
    let u: UserInformation =
    {
      user_id:0,
      expired: false,
      subscribed: false,
      subscriber_level: SubscriberLevel.none,
      logged_in: false,
      status: SubscriptionStatus.unknown
    }

    return u;

  }


}


