import '@ungap/global-this';

//import { ApolloProvider } from '@apollo/client'
import { AppContext, AppProps} from 'next/app'

// eslint-disable-next-line unused-imports/no-unused-imports
import { Component, useEffect } from 'react'
//import { MenuProvider } from '../components/Header'


import '../styles/wordpress.scss';
import '../styles/styles.scss';

import { ApolloProvider } from '@apollo/client';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Router } from 'next/router';
import CookieNotice from '../components/CookieNotice';
import ErrorBoundary from '../components/ErrorBoundary';
import GenericHead from '../components/Head';
import { MenuProvider } from '../components/Header';
import { apolloClient } from '../lib/apollo';
import { RusAuthProps, RusContextProps, CurrencyRate, Tutor, UserInformation, SubscriberLevel, SubscriptionStatus, LocalizationInformation } from '../lib/definitions';
import { localCache } from '../lib/localcache';
import { consoleError, consoleLog, handleAxiosError, logEvent, registerBrowser } from '../lib/logger';
import { RawUmberProvider } from '../lib/rawumberprovider';
import useAuth, { AuthProvider, getUserFromAuthToken } from '../lib/useAuth';
import * as Sentry from "@sentry/nextjs";

import { install as resizeObserverInstall } from 'resize-observer';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import TokenRefresher from '../components/TokenRefresher';
import Head from 'next/head';

// eslint-disable-next-line unused-imports/no-unused-imports
//import { localCache } from '../lib/localcache'
//import { RusContextProps, RusAuthProps,} from '../lib/definitions';
//import { logEvent, registerBrowser } from '../lib/logger';
//import { AuthProvider } from '../lib/useAuth';
//import { RawUmberProvider } from '../lib/rawumberprovider';
//import { MantineProvider } from '@mantine/core';

//import { ApolloProvider } from '@apollo/client';
//import ErrorBoundary from '../components/ErrorBoundary';

//import { apolloClient } from '../lib/apollo';
//import { Router } from 'next/router';

/**
 * Custom App component.
 *
 * @see https://nextjs.org/docs/advanced-features/custom-app
 * @see https://www.apollographql.com/docs/react/api/react/hooks/#the-apolloprovider-component
 */

//import { Analytics }  from '@vercel/analytics/react';

/*
function Fallback(props : {error: Error,resetErrorBoundary: any}) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  consoleError(props.error);

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{props.error.message}</pre>
      <p>Sorry about that.</p>
    </div>
  );
}*/


//globalThis polyfill


export default function RusApp({ Component, pageProps, canonicalUrl, ...rusAllProps }: AppProps & RusContextProps& RusAuthProps & {canonicalUrl:string} ) {

let rusAuthProps = (rusAllProps as RusAuthProps);
let rusContextProps = (rusAllProps as RusContextProps);


   //console.log("In app");
   
   if (typeof window !== 'undefined')
   {
     if (!window.ResizeObserver)
     {
      resizeObserverInstall();
     }
   }
   
    function logPageView(url: string)
    {
      logEvent("page_loaded", {page: url});
    }

    useEffect(() =>
    {


     // consoleLog("In useEffect");

      async function rb()
      {
      

        //consoleLog("In rb");

        try
        {
          await registerBrowser();
        }
        catch (e)
        {
          Sentry.addBreadcrumb({message:"Couldn't register browser"});
          Sentry.captureException(e);
        }


         logPageView(window.location.href);
         // Subsequent route changes
         Router.events.on('routeChangeComplete',  (url: string) =>
         {

           logEvent("page_loaded", { page: url });
  
          });

          //consoleLog("Out of rb");

      
      }

      const isBrowser = typeof window !== 'undefined';
/*
      function pausecomp(millis)
    {
        let date = new Date();
        let curDate = new Date();

        do { curDate = new Date(); }
        while(curDate.getTime()-date.getTime() < millis);
    }*/

    if (!isBrowser)
    {
      //this should never happen!
      return;
    }


        rb();
        //consoleLog("Out of useEffect");

      //consoleLog("Out of useEffect");

      //set up token refresh every 30 minutes

      return (()=>{
        Router.events.off('routeChangeComplete', logPageView);});

    }


  ,[]);


    return (
      <>
      <Head>
        <link rel='canonical' href={canonicalUrl} key="canonical"/>
      </Head>
      <ErrorBoundary> 
          <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{

          colorScheme: 'light',
          //fontFamily: 'Open Sans'
        }}
      >
        <ModalsProvider>
        <ApolloProvider client={apolloClient}>
            <MenuProvider>
          
                <AuthProvider {...rusAuthProps}>
                  <TokenRefresher/>
                  <RawUmberProvider {... rusContextProps}>
              
                            <GenericHead/>
                           
                           <ErrorBoundary>
                            <Component {...{...pageProps, ...rusAllProps}} />
                            </ErrorBoundary>
                           
                         
                      </RawUmberProvider>
                </AuthProvider>
       
            </MenuProvider>
                    </ApolloProvider>
                
                    </ModalsProvider>
                            </MantineProvider>
                                </ErrorBoundary>
                                </>
    );
      }



  RusApp.getInitialProps = 
  async (context: AppContext) => 
  {


    //consoleLog("Starting get initial props");
    
    let ck = '';

    let options : AxiosRequestConfig = { method: 'GET',  headers: {'Cache-Control': 'no-store'}, withCredentials:true};
    
    if (context.ctx)
    {
      if (context.ctx.req?.headers)
      {
        ck=context.ctx.req?.headers.cookie as string;
        options.headers =
        {
          'Cache-Control': 'no-store',
          'Cookie' : ck
        };
      }
  } 
  
    Sentry.addBreadcrumb({message: "About to call JWT token"});

    let userInfo : UserInformation =
    {
      user_id:0,
      expired: false,
      subscribed: false,
      subscriber_level: SubscriberLevel.none,
      logged_in: false,
      status: SubscriptionStatus.unknown
    }
    
    let token="";

    try
    {
      //this might be client or server side, so don't rely on axios interceptor
      let at = await axios.get(process.env.NEXT_PUBLIC_API_ROUTE + '/api/getjwttoken?safari=' + Math.floor(Math.random() * 100000), options);
      let k = at.data;
      Sentry.setContext("token", {value: k});
      userInfo = await getUserFromAuthToken(k.token);
      token = k.token;

    }
    catch (err)
    {
      handleAxiosError(err);      
    }


  //  let at =  await fetch(process.env.NEXT_PUBLIC_API_ROUTE + '/api/getjwttoken', options);

   // let k = await at.json();
/*
    Sentry.setContext("token", {value: k});

    if (typeof window === 'undefined')
    {
      //same call
      Sentry.addBreadcrumb({message: "About to call getUserFromAuthToken with " + k});
    }
    else
    {
      Sentry.addBreadcrumb({message: "About to call getUserFromAuthToken with " + k});
    }
*/


    if (typeof window != 'undefined')
    {
      Sentry.setContext("user", {value:userInfo});
    }

    let loggedIn = (userInfo.user_id!=0);

    let options2 = { 
      method: 'GET',
      cache: "no-store" as RequestCache
      };

    let currencies = await localCache.getObject<CurrencyRate[]>("currencies_2", async () => {

      let at2 =  await fetch(process.env.NEXT_PUBLIC_API_ROUTE + '/api/getcurrencies', options2);
      let c2 = await at2.json();
      return c2;

    }, 60 * 5); 

    let tutors = await localCache.getObject<Tutor[]>("tutors", async () => {

      let at3 =  await fetch(process.env.NEXT_PUBLIC_ELTON_ROUTE + '/tutors', options2);
      let t2 = (await at3.json() as Tutor[]);
      return t2;

    }, 60 * 5); 

    let li : LocalizationInformation | null = null;

    if (context.ctx && context.ctx.req?.headers)
    {
      //NGINX is caching by country code
      let cfGeo = context.ctx.req?.headers['cf-ipcountry'] as string;
      if (cfGeo)
      {
        li ={
          CountryCode:cfGeo,
          GMTOffset:0,
          TimeZoneCode:'GMT',
          CurrencyCode: currencyCodeFromCountryCode(cfGeo)
        }

      }
    }

  //  consoleLog("Exiting getInitialprops");

  const isProd = process.env.NODE_ENV === "production";
  const base = isProd ? "https://rawumberstudios.com/n" : "https://rawumber-test.com/n";
  const { asPath } = context.ctx;
  let canonical = base + asPath; 

  if (canonical.endsWith('/'))
  {
    canonical = canonical.substring(0, canonical.length-1);
  }



    return {
      canonicalUrl: canonical,
      loggedIn: loggedIn,
      userInformation: userInfo,
      authToken: token,
      currencies: currencies,
      //tutorMap: g.tutorMap,
      timeOffset:null,
      hidePopUp: false,
      setHidePopUp: () => {},
      localizationInformation: li,
      tutors: tutors,
      setLocalizationInformation: () => {},
      shoppingCartItemRef: null,
      setShoppingCartItem: () => {}  , 
      //localizationInformation: l    
      logIn: () => {return Promise.resolve();},
      logOut: () => {return Promise.resolve();},
      refreshUserInformation: () => {}

      };
      



}
function currencyCodeFromCountryCode(country: string): string {

  if (country=='UK' || country=='GB')
  {
    return 'GBP';
  }

  if (country=='US')
  {
    return 'USD';
  }

    let euro=[
      'AX',
  'AL',
  'AD',
  'AT',
  'A2',
  'BE',
  'BG',
  'IC',
  'XC',
  'HR',
  'CY',
  'EE',
  'EP',
  'FI',
  'FR',
  'GF',
  'DE',
  'GR',
  'GP',
  'HO',
  'IE',
  'IT',
  'KV',
  'LV',
  'LT',
  'LU',
  'MK',
  'M3',
  'MT',
  'MQ',
  'YT',
  'XL',
  'MC',
  'ME',
  'NL',
  'PT',
  'RE',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'BL',
  'VA'];

  let dollar = [
    'AF',
  'AS',
  'BQ',
  'VG',
  'KM',
  'CW',
  'TL',
  'EC',
  'SV',
  'GU',
  'FM',
  'MH',
  'FM',
  'MP',
  'PW',
  'FM',
  'PR',
  'MP',
  'MP',
  'VI',
  'VI',
  'SX',
  'VI',
  'MP',
  'VG',
  'FM',
  'TC',
  'VI',
  'US',
  'VG',
  'FM'
  ];

  if (euro.includes(country))
  {
    return 'EUR';
  }

  if (dollar.includes(country))
  {
    return 'USD';
  }

  return 'GBP';
}

