import { Button, Text } from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";
import { SubscriberLevel, UserInformation, Video } from "./definitions";
import { consoleLog } from "./logger";

const root = '';

export function getLink(link: string) {
    return root + link;
}

export function TrimDescription(description: string) {

    const maxLength = 225;

    if (description.length < maxLength) {
        return description;
    }

    let short = description.substring(0, maxLength);

    for (let i = short.length - 1; i > 0; i--) {
        if (short[i] == ' ') {
            return short.substring(0, i) + " ...";
        }
    }

    return short;
}

export function okModal(message : string)
{
    openModal({
        title: 'Alert!',
        children: <>
        <Text size="sm">{message}</Text>
        <Button fullWidth onClick={(_e)=> {closeAllModals();}} mt="md">
                  Ok
                </Button>
        </>

        
        });

    }

    export function okModalEx(title:string, message : string)
{
    openModal({
        title: title,
        children: <>
        <Text size="sm">{message}</Text>
        <Button fullWidth onClick={(_e)=> {closeAllModals();}} mt="md">
                  Ok
                </Button>
        </>

        
        });

    }

    export function  sanitizeVideo(video : Video, user : UserInformation)
    {
        switch (user.subscriber_level)
        {
                case SubscriberLevel.gold:
                    video.accessible = true;
                    break;
                case SubscriberLevel.standard:
                    video.accessible = (video.subscriberLevel == SubscriberLevel.none || video.subscriberLevel == SubscriberLevel.standard);
                    break;
                case SubscriberLevel.none:
                case SubscriberLevel.image:
                    video.accessible = (video.subscriberLevel == SubscriberLevel.none);
                    break;
        }
    }

    export function getGBPPriceForSubscription(level: SubscriberLevel)
    {
        switch (level)
        {
            case SubscriberLevel.gold:
                return 13.50;
            case SubscriberLevel.standard:
                return 9.50;
            case SubscriberLevel.image:
                return 5;
            default:
                return 0;
        }
    }

    function isSessionStorageEnabled() : boolean
    {
        try {
          const key = `__storage__test`;
          window.sessionStorage.setItem(key, '');
          window.sessionStorage.removeItem(key);
          return true;
        } 
        catch (_e) {
          return false;
        }
    }

    function getWindowSessionStorage()
    {
        let ss = (window as any).rus_sessionStorage;
        if (!ss)
        {
            (window as any).rus_sessionStorage={};
            ss = (window as any).rus_sessionStorage;
        }

        return ss;

    }
    export function sessionStorageGetItem(key: string)
    {
        if (isSessionStorageEnabled())
        {
            return sessionStorage.getItem(key);
        }
    
        let ss = getWindowSessionStorage();
        let v = ss.key;

        //consoleLog(`Got fallback session storage ${key}=${v}`);
        return v;
    
    }

    export function sessionStorageRemoveItem(key: string)
    {
        if (isSessionStorageEnabled())
        {
            return sessionStorage.removeItem(key);
        }
      
        let ss = getWindowSessionStorage();
        ss.key=undefined;
        //consoleLog(`Removed fallback session storage ${key}`);

    }

    export function sessionStorageSetItem(key: string, value:string)
    {
        if (isSessionStorageEnabled())
        {
            return sessionStorage.setItem(key, value);
        }
      
        let ss = getWindowSessionStorage();
        ss.key=value;
        //consoleLog(`Set fallback session storage ${key}=${value}`);

    }

