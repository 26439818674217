import Link from "next/link"
import Image from "next/image"
import logoImage from '../images/rus-logo.png'
import styles from './Header.module.scss'

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {ReactNode, useEffect, useRef, useState} from "react"
import useAuth  from '../lib/useAuth'


config.autoAddCss = false

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPalette, faUserCircle, faVideo, faBars, faImage, faPlay } from '@fortawesome/pro-solid-svg-icons'

import { IconDefinition } from '@fortawesome/fontawesome-common-types'

import { createContext, useContext } from 'react';

import React from 'react';
import { MantineTransition} from "@mantine/core"
import { useRouter } from "next/router";
import { SubscriptionStatus } from "../lib/definitions";


class MenuItem {
    text: string= "";
    id: MenuId = MenuId.home;
    url: string = "";
    icon?: IconDefinition
}

export enum MenuId {
    home,
    online,
    videos,
    workshops,
    blog,
    subscribe,
    account,
    imageSearch
} 

type menuContextType = {
    currentMenuId: null | MenuId;
    setCurrentMenuId : React.Dispatch<MenuId>
    menuExpanded: boolean;
    setMenuExpanded : React.Dispatch<boolean>
};

const menuContextDefaultValues: menuContextType = {
    currentMenuId: MenuId.home,
    setCurrentMenuId: () => {},
    menuExpanded: false,
    setMenuExpanded: () => {}
   
};

const MenuContext = createContext<menuContextType>(menuContextDefaultValues);

export function useMenuContext() {
    return useContext(MenuContext);
}

type Props = {
    children: ReactNode;
};

export function MenuProvider({ children }: Props) {

    const [currentMenuId, setCurrentMenuId] = useState(MenuId.home);
    const [menuExpanded, setMenuExpanded] = useState(false);

    const router = useRouter();

    
    useEffect(()=>
    {
        router.events.on("routeChangeStart",
        ()=>
        {
            setMenuExpanded(false);
        });
    }
    ,[]);

    function setMenuIdExt(value : MenuId)
    {

        setCurrentMenuId(value);
        setMenuExpanded(false);
    }
    const value = {
        setCurrentMenuId: setMenuIdExt,
        currentMenuId,
        menuExpanded,
        setMenuExpanded
    };

    return (
        <>
            <MenuContext.Provider value={value}>
                {children}
            </MenuContext.Provider>
        </>
    );
}

const topMenu: MenuItem [] = [
    { id: MenuId.home, text: 'Home', url: '/', icon:faHome},
    { id: MenuId.online, text: 'Online sessions', url:'/online/', icon:faVideo },
    { id: MenuId.videos, text: 'Videos', url:'/playlists/', icon:faPlay },
    { id: MenuId.imageSearch, text: 'Image search', url:'/image-search/', icon:faImage },
    { id: MenuId.workshops, text: 'Workshops', url: '/new-events/', icon:faPalette },
    { id: MenuId.blog, text: 'Blog',  url: '/blog/' },
    { id: MenuId.subscribe, text: 'Subscribe!', url: '/subscription' },
    { id: MenuId.account, text: 'Account', url: '/my-account/', icon:faUserCircle }
];



const scaleY : MantineTransition = {
    in: { opacity: 1, transform: 'scaleY(1)' },
    out: { opacity: 0, transform: 'scaleY(0)' },
    common: { transformOrigin: 'top' },
    transitionProperty: 'transform, opacity',
  };

export default function Header(props : {menuId? : MenuId, extraWide?: boolean}) {

   const { currentMenuId, setCurrentMenuId,menuExpanded, setMenuExpanded } = useMenuContext();

   const [menuId, setMenuId] = useState(props.menuId);
   const dropdown = useRef<HTMLDivElement>(null);
   const burger = useRef<HTMLDivElement>(null);

   const { loggedIn, authToken, userInformation } = useAuth();


   
   useEffect(()=>
   {

    function handleClick( event:MouseEvent)
    {


        let e = event.target as Node;
        //console.log(dropdown.current);
        //console.log(e);
        if (dropdown.current)
        {
        //console.log(dropdown.current.contains(e));
        }


        if (menuExpanded && dropdown.current && !dropdown.current.contains(event.target as Node)) {
     
         
            setMenuExpanded(false);
          }
    }
    
    if (menuExpanded)
    {

    window.addEventListener("click", handleClick, {capture:true});
    return () => {window.removeEventListener("click", handleClick, {capture:true});};
    }
    
   }, [menuExpanded]);

   
   function toggleMenu()
   {
        setMenuExpanded(!menuExpanded);
   }

   function renderMenu(menu: MenuItem) {

    if ((loggedIn && userInformation?.subscribed && userInformation?.status != SubscriptionStatus.pendingCancellation) && menu.id == MenuId.subscribe)
    {

        return <div key = {menu.id}></div>
    }
    
    let icon = <></>
    if (menu.icon !== undefined) {
        icon = <><FontAwesomeIcon icon={menu.icon}/> </>
    }

    let c = '';

    if (menu.id == menuId) {
        c = styles.selected;
    }
    else if (menu.id == MenuId.subscribe) {
        c = styles.highlit;
    }

    //let a = styles.selected;
    let link = menu.url;
    //let link = '/';
    let text = menu.text;

    if (menu.id == MenuId.account) {
        
        if (loggedIn) {
            text = 'Account';
            link = '/my-account';
        }
        else
        {
            text = "Log in";
            link = "/log-in";
        }
    }

    if (menu.id == MenuId.online)
    {
        if (loggedIn)
        {
            link='/upcoming-online-sessions';
        }
    }
    return <li key={menu.id} className={c}><Link href={link} onClick={()=>{menuClick(menu.id)}}>{icon}{text}</Link></li>
}


   function renderTopMenu(long: boolean) {



        let tb = <div ref={burger} key='top' className={styles.topBar} onClick={()=> {toggleMenu();}}><FontAwesomeIcon  className='fa-2x' icon={faBars}/></div>

        if (!long)
        {


            if (menuExpanded)
            {
                
                return <div ref={dropdown}>
                {tb}
                <ul className={styles.expandedMenu}>{topMenu.map(menu => { return renderMenu(menu); })}</ul></div>                   
                    
            }

            return <>{tb}</>
        }
        return (<>{tb}<ul>{topMenu.map(menu => { return renderMenu(menu); })}</ul></>);


    }

    function menuClick(menuId : MenuId)
    {
        setMenuId(menuId);
        setCurrentMenuId(menuId);
        setMenuExpanded(false);
    }


    let style = styles.header + (props.extraWide?(" " + styles.extraWide):"");

    return (
        <div className={styles.masthead}>
        <header className={style} >
            <div className={styles.sitelogo}>
                <Link href='/'><Image src={logoImage} alt='Logo' width='90' height='50' /></Link>
            </div>

            <div className={styles.navbarShort} >
                {renderTopMenu(false)}
                </div>
                <div className={styles.navbarLong} >
                {renderTopMenu(true)}
                </div>

                
            </header>
 
            </div>

    )
}